<template>
<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 16 16"
>
  <path
    fill="#fff"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm.534 8.468v4.865H6.576V8.468H4.94V6.572h1.637V5.173c0-1.623.991-2.506 2.44-2.506.692 0 1.289.051 1.462.074v1.696H9.474c-.787 0-.94.375-.94.924v1.21h1.878l-.245 1.897H8.535Z"
  />
</svg>
</template>

<script>
export default {}
</script>
