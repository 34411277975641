<template>
  <nav
    ref="nav"
    :class="['nav', { 'nav_opened': opened }, { 'nav_public': publicModifier }]"
    role="navigation"
  >
    <ul class="nav__menu">
      <li
        v-if="!isLoggedIn"
        class="nav__item nav__item_new"
      >
        <a
          class="nav__link nav__link_mobile-bold nav__link_bold"
          data-testid="headerMenuSwitchToRss"
          :href="howToStartPodcastLink"
          target="_blank"
        >
          {{ $t('sentences.how_to_start_a_podcast') }}
        </a>
      </li>

      <li class="nav__item">
        <NuxtLink
          class="nav__link nav__link_mobile-bold nav__link_bold"
          :to="$locatedLink('/features/')"
        >
          {{ $t('single_words.features') }}
        </NuxtLink>
      </li>

      <li class="nav__item">
        <NuxtLink
          class="nav__link nav__link_mobile-bold nav__link_bold"
          :to="$locatedLink('/pricing/')"
        >
          {{ $t('modules.navigation.pricing') }}
        </NuxtLink>
      </li>

      <li class="nav__item">
        <NuxtLink
          class="nav__link nav__link_mobile-bold nav__link_bold"
          :to="$locatedLink('/community/')"
        >
          {{ $t('single_words.podcasts') }}
        </NuxtLink>
      </li>

      <li v-if="isLoggedIn" class="nav__item">
        <a
          class="nav__link nav__link_mobile-bold nav__link_bold"
          target="_blank"
          :href="supportLink"
        >
          {{ $t('modules.navigation.support') }}
        </a>
      </li>

      <template v-if="!isLoggedIn">
        <li class="nav__item nav__item_desktop nav__item_sep">
          <RssUiButton
            class="nav__link nav__link_sign-up"
            rainbow
            :blank="false"
            :text-size="16"
            text-color="#fff"
            data-test-id="headerSignUpButton"
            :href="$locatedLink('/auth/sign-up/', dashboardAppUrl)"
          >
            {{ $t('sign_up_free') }}
          </RssUiButton>
        </li>

        <li class="nav__item nav__item_wave nav__item_mobile">
          <img
            class="nav__wave"
            alt=""
            src="/images/wave_yellow.svg"
          >
        </li>

        <li class="nav__item nav__item_mobile">
          <a
            class="nav__link"
            :href="$locatedLink('/auth/sign-up/', dashboardAppUrl)"
          >
            {{ $t('sign_up_free') }}
          </a>
        </li>

        <li class="nav__item">
          <a
            class="nav__link"
            data-testid="headerSignInButton"
            :href="$locatedLink('/auth/sign-in/', dashboardAppUrl)"
          >
            {{ $t('sign_in') }}
          </a>
        </li>
      </template>

      <template v-else>
        <li class="nav__item nav__item_user">
          <BDropdown
            ref="userMenu"
            aria-role="list"
            data-testid="userMenu"
            position="is-bottom-left"
            class="nav__dropdown"
            animation="none"
            @active-change="changeDropdownActiveState"
          >
            <button
              slot="trigger"
              class="nav__button nav__button_avatar"
              data-testid="userMenuTrigger"
            >
              <span class="nav__mob-avatar">{{ $t('modules.navigation.account') }}</span>

              <UiAvatar
                class="nav__avatar"
                :size="48"
                :border="3"
                :photo="avatarPhotoUrl"
              />
            </button>

            <BDropdownItem
              class="dropdown-item"
              :has-link="true"
              aria-role="listitem"
            >
              <a
                class="nav__menu-link nav__menu-link_no-icon"
                data-testid="userMenuInvoicesItem"
                :href="$locatedLink('/', dashboardAppUrl)"
              >
                {{ $t('modules.navigation.dashboard') }}
              </a>
            </BDropdownItem>

            <BDropdownItem :has-link="true" aria-role="listitem">
              <div
                class="nav__menu-link"
                :class="{'nav__menu-link_open': isSettingsSubnavOpen}"
                @click.stop="onClickSettingsDropdownItem"
              >
                {{ $t('modules.navigation.account') }}
                <div class="nav__menu-link-icon">
                  <i class="rss-icon-chevron-right-solid" />
                </div>
              </div>

              <div
                v-show="isSettingsSubnavOpen"
                class="subnav"
              >
                <a
                  class="nav__menu-link nav__menu-link_sub"
                  data-testid="userMenuAccountItem"
                  :href="$locatedLink('/account/', dashboardAppUrl)"
                >
                  {{ $t('modules.navigation.my_profile') }}
                </a>
                <a
                  class="nav__menu-link nav__menu-link_sub"
                  data-testid="userMenuSubscriptionItem"
                  :href="$locatedLink('/subscription/', dashboardAppUrl)"
                >
                  {{ $t('modules.navigation.subscription') }}
                </a>
                <a
                  class="nav__menu-link nav__menu-link_sub"
                  data-testid="userMenuPrivacyItem"
                  :href="$locatedLink('/privacy/', dashboardAppUrl)"
                >
                  {{ $t('modules.navigation.privacy') }}
                </a>
              </div>
            </BDropdownItem>

            <BDropdownItem :has-link="true" aria-role="listitem">
              <a
                class="nav__menu-link nav__menu-link_no-icon"
                @click.prevent="logOut"
              >
                {{ $t('log_out') }}
              </a>
            </BDropdownItem>
          </BDropdown>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { config } from '@/config'

export default {
  props: {
    opened: Boolean,
    publicModifier: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isSettingsSubnavOpen: false
    }
  },

  computed: {
    ...mapState('window', ['screen']),
    ...mapState('auth', ['avatarPhotoUrl', 'emailVerified', 'isLoggedIn']),

    ...mapGetters('podcasts', ['podcastIsRedirected']),

    baseUrl () {
      return config.baseUrl
    },

    dashboardAppUrl () {
      return config.dashboardAppUrl
    },

    isOnPodcastPage () {
      return this.$route.params.slug
    },

    isOnTheDashboard () {
      return !!this.$route.path.includes('/dashboard')
    },

    supportLink () {
      return config.supportLink + '/' + this.$i18n.locale + '/support/home'
    },

    howToStartPodcastLink () {
      if (this.$i18n.locale === 'es') {
        return 'https://rss.com/blog/que-es-el-podcasting-y-por-que-deberias-empezar-uno/'
      }

      if (this.$i18n.locale === 'it') {
        return 'https://rss.com/blog/stai-pensando-di-creare-un-podcast-ecco-7-cose-da-sapere/'
      }

      return 'https://rss.com/blog/thinking-of-starting-a-podcast/'
    }
  },

  watch: {
    '$route' () {
      if (!this.$refs.userMenu) {
        return
      }

      this.$refs.userMenu.isActive = false
    },

    screen () {
      if (!this.$refs.userMenu) {
        return
      }

      this.$refs.userMenu.isActive = false
    }
  },

  mounted () {
    const $nav = this.$refs.nav
    $nav.addEventListener('click', this.onClickNavLinkHandler)
  },

  beforeDestory () {
    const $nav = this.$refs.nav
    $nav.removeEventListener('click', this.onClickNavLinkHandler)
  },

  methods: {
    ...mapActions('auth', ['signOut']),

    onClickNavLinkHandler (e) {
      if (e.target.classList.contains('nav__link')) {
        this.$emit('close')
      }
    },

    onClickSettingsDropdownItem (e) {
      e.preventDefault()
      this.isSettingsSubnavOpen = !this.isSettingsSubnavOpen
    },

    async logOut () {
      const result = await this.signOut()
      if (result.success) {
        // eslint-disable-next-line
        window.location = this.$locatedLink('/auth/sign-in/', this.dashboardAppUrl)
      }
    },

    changeDropdownActiveState (value) {
      this.$emit('change-dropdown-active', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.nav {
  font-size: 14px;

  &_dark {
    .nav__link {
      color: #fff;

      @include display-less(desktop) {
        color: $colorText;
      }
    }
  }

  &__item {
    &_new {
      display: flex;
      align-items: center;

      .nav__link {
        @include display(desktop) {
          padding: 0;
        }
      }

      .nav__item-new {
        color: #fff;
        height: 22px;
        line-height: 1;
        font-size: 12px;
        padding: 4px 8px;
        margin-left: $gap * 0.75;
        border-radius: 4px;
        transition: all .2s ease;
        background: rgba(191, 161, 235, 0.3);

        .nav_opened & {
          margin-left: 0;
          background-color: $colorAccent;
        }
      }
    }
  }

  &__link {
    @extend %resetButton;
    vertical-align: bottom;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
    &_sign-up {
      display: inline-flex;
    }
  }

  &__button {
    @extend %resetButton;
    border-radius: 50%;

    &_avatar {
      .dropdown & {
        margin-top: -12px !important;
      }

      @include display(desktop) {
        .nav__mob-avatar {
          display: none;
        }
      }

      @include display-less(desktop) {
        width: 100%;
        border-radius: 0;

        .dropdown & {
          margin-top: 0px!important;
        }

        .nav__mob-avatar {
          color: #2D2D2D;
          font-weight: 600;
          line-height: 48px;
        }

        .nav__avatar {
          display: none;
        }
      }
    }
  }

  &__avatar {
    vertical-align: bottom;
  }

  &__menu-link {
    width: 210px;
    cursor: pointer;
    overflow: hidden;
    font-weight: 700;
    line-height: 20px;
    position: relative;
    align-items: center;
    white-space: nowrap;
    padding: 0!important;
    text-decoration: none;
    background-color: #fff;
    text-overflow: ellipsis;
    display: flex!important;
    transition: all .2s ease;
    font-size: 14px!important;
    justify-content: space-between;

    &-icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      color: $colorWarmGray;
      justify-content: center;
      transition: transform .2s ease, color .2s ease;
    }

    &_no-icon {
      @include display-less(desktop) {
        .nav__menu-link-icon {
          display: none;
        }
      }
    }

    &:hover {
      color: $colorPurple!important;
      background-color: transparent!important;

      .nav__menu-link-icon {
        color: $colorPurple!important;
      }
    }

    @include display(desktop) {
      max-width: 264px;
    }

    @include display-less(desktop) {
      width: 100%;
    }

    &_open {
      .nav__menu-link-icon {
        transform: rotate(-90deg);
      }
    }

    // Subnavigation link
    &_sub {
      color: #2D2D2D;
      font-size: 16px!important;
      font-weight: 400;
      line-height: 150%;

      &:not(:last-child) {
        margin-bottom: $gap * 1.5;
      }

      @include display-less(desktop) {
        font-size: 16px;
        font-weight: 400!important;
        line-height: 150%;
      }
    }
  }

  &__dropdown {
    /deep/ .has-link {
      a {
        color: #2D2D2D!important;
      }
    }

    /deep/ .dropdown-menu {
      overflow: hidden;
      min-width: 245px;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0px 4px 21px -5px rgba(36, 38, 53, 0.22);

      @include display-less(desktop) {
        display: block!important;
      }
    }
  }

  @include display-less(desktop) {
    position: relative;
    right: 0;
    left: 0;

    &__dropdown {
      @media screen and (max-height: 630px) {
        /deep/ &.dropdown.is-mobile-modal > .dropdown-menu {
          top: 72px!important;
          transform: translate3d(-50%, 0%, 0)!important;
        }
      }
    }

    &__item {
      opacity: 0;
      text-align: left;

      a {
        font-size: 14px;
        font-weight: 700;
        line-height: 150%;
      }

      &_user {
        margin-top: 0px;

        /deep/ {
          .background {
            background-color: transparent;
          }
        }
      }

      &_mobile {
        display: block;

        &-hide {
          display: none;
        }
      }

      &_desktop {
        display: none;
      }

      &_wave {
        padding: $gap 0;

        @include display-less(desktop) {
          padding: $gap;
        }
      }

      .nav_opened & {
        opacity: 1;
        transition: opacity $speed $speed ease-out;
      }

      .nav-closing & {
        opacity: 0;
      }

      &:not(:last-child) {
        margin-left: 0;
        margin-bottom: $gap * 2;
      }
    }

    &__link {
      display: block;
      padding: 0 $gap;
      color: $colorText;
      line-height: $gap*3;
      text-decoration: none;

      &_mobile-bold {
        font-weight: 600;
      }

      @include display-less(desktop) {
        font-weight: 600;
      }
    }

    &__wave {
      margin: 0 auto;
    }
  }

  @include display(desktop) {
    &__menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__item {
      font-weight: 500;

      &_mobile {
        display: none;
      }

      &_desktop {
        display: block;
      }

      &_sep {
        &:not(:first-child) {
          padding-left: 24px;
          border-left: 2px solid rgba(#fff, .5);

          @include display-less(hd) {
            padding-left: $gap * 0.5;
          }
        }
        transition: border-color $speed;

        .nav_dark-text & {
          border-color: rgba($colorText, .5);
        }
      }

      margin-left: $gap * 1.5;
    }

    &__link {
      display: inline-block;
      padding: 0 8px;
      border: 1px solid transparent;
      border-radius: 5px;
      color: $colorLight;
      line-height: 24px;
      text-decoration: none;
      transition: color $speed, border-color $speed;

      &_sign-up {
        display: inline-flex;
      }

      .nav_dark-text & {
        color: $colorText;
      }

      &_bold {
        font-weight: 600;
      }

      &_disabled {
        opacity: 0.2;
        display: block;
        position: relative;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  @include display-less(hd) {
    &__item {
      margin-left: $gap * 0.5;
    }
  }

  &__button {
    margin: -4px 0 !important;
    font-weight: inherit !important;

    &-text {
      color: $colorLight;
      background: $colorDark !important;
      transition: background $speed, color $speed;
    }

    @include display-less(desktop) {
      &-text {
        background: $colorDark !important;
        color: $colorLight;
        transition: background $speed, color $speed;
      }
    }
  }

  .dropdown {
    &.is-mobile-modal {
      /deep/ .dropdown-menu {
        padding-top: 0;

        top: auto!important;
        left: auto!important;
        transform: none!important;
        box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.12);

        @include display-less(desktop) {
          box-shadow: none;
        }
      }
      /deep/ .dropdown-content {
        box-shadow: none;
        padding: $gap * 1.5;

        > div[role="listitem"] {
          &:not(:last-child) {
            margin-bottom: $gap * 1.5;

            @include display-less(desktop) {
              margin-bottom: $gap * 2;
            }
          }
        }

        @include display-less(desktop) {
          width: 100%;
          padding: $gap;
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      /deep/ .dropdown-menu {
        overflow: visible;
        @include display-less(desktop) {
          width: 100%;
        }
      }
    }
  }
}
.subnav {
  width: 100%;
  padding-top: 20px;
  padding-left: $gap;
}

.dropdown-item {
  padding: 0!important;

  > a {
    padding: 0!important;
  }
}
.dropdown-item.dropdown-item__mobile-hide {
  @include display-less(desktop) {
    display: none!important;
  }
}

</style>

<style lang="scss">
.nav {
  @media screen and (min-width: 960px) and (max-width: 1024px) {
    .dropdown.is-bottom-left .dropdown-menu {
      overflow: visible !important;
      top: 48px !important;
      right: $gap !important;
      left: auto !important;
      width: auto !important;
      margin-top: 28px;
      transform: none !important;
    }
  }

  @include display-less(desktop) {
    .dropdown {
      width: 100%;
      &-trigger {
        width: 100%;
      }
    }
  }

  @include display(desktop) {
    .dropdown {
      height: 24px;
    }

    .dropdown.is-bottom-left .dropdown-menu {
      margin-top: 50px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 8px;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        background: #fff;
        transform: rotate(45deg);
      }

      &::before {
        box-shadow: -2px -2px 13px -1px rgba(14,8,60,0.12);
      }
    }

    .dropdown-content {
      position: relative;
      margin-top: 2px;
      border: none;
      box-shadow: 0 4px 13px -1px rgba(14,8,60,0.12);
      background: #fff;

      & > div {
        position: relative;
        background: #fff;
        z-index: 21;
      }
    }
  }
}
</style>
