export default {
  async searchDiscoverPodcasts ({ commit, dispatch, getters }, payload = {}) {
    const limit = payload.limit || 9

    let podcasts = []

    try {
      podcasts = await dispatch('api/fetch', {
        endpoint: `/podcasts/discover?limit=${limit}`,
        public: true
      }, { root: true })
    } catch (error) {}

    return podcasts
  },

  async fetchFeaturedPodcasts ({ commit, dispatch, getters }, payload = {}) {
    const limit = payload.limit || 9

    let podcasts = []

    try {
      podcasts = await dispatch('api/fetch', {
        endpoint: `/podcasts/featured?limit=${limit}`,
        public: true
      }, { root: true })
    } catch (error) {}

    return podcasts
  },

  async fetchPodcastsBasedOnItunesCategory ({ commit, dispatch, getters }, payload = {}) {
    const page = payload.page || 1
    const limit = payload.limit || 9
    const itunesCategoryId = payload.itunesCategoryId

    let podcasts = []

    try {
      podcasts = await dispatch('api/fetch', {
        endpoint: `/search/podcasts/itunes-category/${itunesCategoryId}?limit=${limit}&page=${page}`,
        public: true
      }, { root: true })
    } catch (error) {
      console.log(error)
    }

    return podcasts
  },

  async fetchItunesCategoriesList ({ commit, dispatch, getters }, payload) {
    let itunesCategories = []

    try {
      itunesCategories = await dispatch('api/fetch', {
        endpoint: '/search/podcasts/itunes-categories',
        public: true
      }, { root: true })
    } catch (error) {
      console.log(error)
    }

    return itunesCategories
  },

  async fetchPodcastsBasedOnTitle ({ commit, dispatch, getters }, payload) {
    let podcasts = []

    const page = payload.page || 1
    const title = payload.title
    const limit = payload.limit

    try {
      podcasts = await dispatch('api/fetch', {
        public: true,
        method: 'POST',
        endpoint: `/search/podcasts/title?limit=${limit}&page=${page}`,
        data: {
          title
        }
      }, { root: true })
    } catch (error) {
      console.log(error)
    }

    return podcasts
  },

  async fetchRandomPodcasts ({ dispatch }, payload) {
    const limit = payload.limit

    let podcasts = []

    try {
      podcasts = await dispatch('api/fetch', {
        endpoint: '/podcasts/discover?limit=' + limit,
        public: true
      }, { root: true })
    } catch (error) {
      console.log(error)
    }

    return podcasts
  },

  async fetchRandomEpisode ({ dispatch }, payload) {
    const limit = payload.limit

    let episodes = []

    try {
      episodes = await dispatch('api/fetch', {
        endpoint: '/episodes/discover?limit=' + limit,
        public: true
      }, { root: true })
    } catch (error) {
      console.log(error)
    }

    return episodes
  }
}
