<template lang="pug">
.audio-player
  .audio-player__full-screen(
    v-if="currentEpisode"
    :class="{'audio-player__full-screen-absolute': isFullScreen}"
    v-show="isFullScreen"
    ref="fullScreenMode"
  )
    FullScreenPlayer(
      :isFullScreen="isFullScreen"
      @fullScreenChange="(value) => isFullScreen = value"
    )
  .audio-player__blur(
    v-if="isBlurRendered"
  )
  .audio-player__box(
    v-if="currentEpisode"
    :class="{'audio-player__box-hidden': isFullScreenPlayerHidden}"
    :style="{'--timeline-progress': timelineProgress}"
  )
    TutorialTooltip.audio-player__tooltip(
      :label="$t('tutorials.player.full_screen')"
      color="light"
      align="center"
      tutorialId="tutorialPlayerFullScreenMobile"
      onlyMobile
    )
      .audio-player__container
        Info.audio-player__container-info(
          :isFullScreen="isFullScreen"
          @fullScreenChange="(value) => isFullScreen = value")
        Controller(
          @playPauseClick="playPause"
        )
        Settings.audio-player__container-settings(
          @fullScreenChange="(value) => isFullScreen = value"
        )
        audio(
          preload="none"
          ref="audioElement"
          @timeupdate="timeUpdateHandler"
        )
          source(
            type='audio/mpeg'
            :src='episodeAudioUrl'
          )
</template>

<script>
import { mapState, mapActions } from 'vuex'

import { config } from '@/config'
import { prettifyAudioDuration } from '@/lib/utils'

import FullScreenPlayer from '@/components/modules/audio-player/full-screen-player/FullScreenPlayer.vue'
import Info from '@/components/modules/audio-player/Info.vue'
import Controller from '@/components/modules/audio-player/Controller.vue'
import Settings from '@/components/modules/audio-player/Settings.vue'
import TutorialTooltip from '@/components/shared/elements/TutorialTooltip'

export default {
  components: {
    FullScreenPlayer,
    Info,
    Controller,
    Settings,
    TutorialTooltip
  },

  data () {
    return {
      initialized: false,
      isFullScreen: false
    }
  },

  computed: {
    ...mapState('player', [
      'status',
      'currentTime',
      'currentEpisode',
      'currentPodcast',
      'loading',
      'skipTo',
      'volume',
      'tooltipsId'
    ]),

    podcastSlug () {
      return this.currentPodcast.slug.toLowerCase()
    },

    episodeAudioUrl () {
      if (!this.currentEpisode || !this.currentEpisode.episode_asset) {
        return ''
      }
      return `${config.mediaUrl}/${this.podcastSlug}/${this.currentEpisode.episode_asset}`
    },

    episodeDuration () {
      return this.currentEpisode.itunes_duration
    },

    timelineProgress () {
      return parseFloat(this.currentTime / this.episodeDuration) * 100 + '%'
    },

    isBlurRendered () {
      return this.tooltipsId.includes('tutorialPlayerFullScreenMobile') && this.tooltipsId.includes('tutorialPlayerFullScreenDesktop')
    }
  },

  watch: {
    async currentEpisode (newValue, oldValue) {
      if (!oldValue && newValue) {
        this.$nextTick(() => {
          const audio = this.$refs.audioElement

          audio.addEventListener('ended', () => {
            this.setPlayerStatus('stopped')
          })
          // Audio - On can play event
          audio.addEventListener('canplay', () => {
            if (this.status === 'playing') {
              this.setLoadingStatus(false)
              audio.play()
            }
          })
        })
      }

      const chapters = await this.getEpisodeChaptersByEid(this.currentEpisode.id)
      this.setChapters(chapters)

      this.$nextTick(() => {
        const audioElement = this.$refs.audioElement
        audioElement.pause()
        if (this.status === 'playing') {
          audioElement.load()
          audioElement.currentTime = 0
        } else if (this.status === 'stopped') {
          this.forceAudioLoad = true
        }
      })
    },

    status (value) {
      this.$nextTick(() => {
        const audioElement = this.$refs.audioElement
        if (value === 'playing') {
          const readyState = audioElement.readyState
          if (readyState > 1) {
            audioElement.play()
          }
        } else {
          audioElement.pause()
        }
      })
    },

    skipTo (value) {
      this.$nextTick(() => {
        if (value !== null) {
          const audioElement = this.$refs.audioElement
          audioElement.currentTime = Math.min(Math.max(value, 0), this.getEpisodeDuration() - 1)
          this.setCurrentTime(audioElement.currentTime)
          this.setSkipTo(null)
        }
      })
    },

    isFullScreen (value) {
      this.$nextTick(() => {
        const fullScreenEl = this.$refs.fullScreenMode
        if (fullScreenEl && fullScreenEl.requestFullscreen) { // Supports Fullscreen API
          if (value) {
            fullScreenEl.requestFullscreen()
          } else if (document.fullscreenElement) {
            document.exitFullscreen()
          }
        }
      })
    },

    volume (value) {
      const audio = this.$refs.audioElement
      audio.volume = value
    }
  },

  mounted () {
    if (!this.initialized) {
      this.initialized = true
      this.unlockAudioContext()

      window.addEventListener('fullscreenchange', this.handleFullScreenChange)
    }
  },

  beforeDestroy () {
    window.removeEventListener('fullscreenchange', this.handleFullScreenChange)
  },

  methods: {
    ...mapActions('community', ['fetchRandomEpisode']),
    ...mapActions('podcasts', ['get', 'getEpisodeById', 'getEpisodeChaptersByEid']),
    ...mapActions('player', [
      'setCurrentPlayingPodcast',
      'setPlayList',
      'setChapters',
      'setPlayerStatus',
      'setCurrentPlayingEpisode',
      'setLoadingStatus',
      'setCurrentTime',
      'setCountdown',
      'setSkipTo'
    ]),

    getEpisodeDuration () {
      if (this.currentEpisode.itunes_duration) {
        return this.currentEpisode.itunes_duration
      }
      if (this.$refs.audioElement) {
        return this.$refs.audioElement.duration
      }
      return 0
    },

    unlockAudioContext () {
      // eslint-disable-next-line
      const AudioContext = window.AudioContext || window.webkitAudioContext
      const audioCtx = new AudioContext()

      if (!audioCtx || audioCtx.state !== 'suspended') {
        return
      }

      const b = document.body

      const clean = () => {
        events.forEach((e) => {
          b.removeEventListener(e, unlock)
        })
      }

      const unlock = () => {
        audioCtx.resume().then(clean)
      }

      const events = ['touchstart', 'touchend', 'mousedown', 'keydown']

      events.forEach((e) => {
        b.addEventListener(e, unlock, false)
      })
    },

    playPause () {
      if (this.status === 'playing') {
        this.setPlayerStatus('stopped')
      } else {
        const audioElement = this.$refs.audioElement
        if (this.forceAudioLoad || audioElement.readyState === 0) {
          this.forceAudioLoad = false
          const currentTime = this.currentTime
          audioElement.load()
          // audio.load() sets current time at 0
          this.setSkipTo(currentTime)
        }
        this.setPlayerStatus('playing')
      }
    },

    timeUpdateHandler () {
      const audio = this.$refs.audioElement
      this.setCurrentTime(audio.currentTime)

      const secondsLeft = this.getEpisodeDuration() - audio.currentTime
      this.setCountdown(prettifyAudioDuration(Math.min(Math.max(secondsLeft, 0), this.getEpisodeDuration())))
    },

    handleFullScreenChange () {
      this.isFullScreen = !!document.fullscreenElement
    }
  }
}
</script>
<style lang="scss" scoped>
.tooltip {
  width: 100%;
  position: relative;
  height: 100%;
}

.audio-player {
  left: 0;
  bottom: 0;
  width: 100%;
  position: fixed;
  z-index: 40;

  &__full-screen {
    &-absolute {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 45;
    }
  }

  &__tooltip {
    width: 100%;
    position: relative;
    height: 100%;
  }

  &__blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px);
  }

  &__container {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1 1 0px;
    padding-top: $gap;
    padding-bottom: $gap;
    align-items: center;
    position: relative;
    gap: $gap;
    margin: 0 54px;

    @include display-less(phablet) {
      margin-left: $gap;
      margin-right: $gap;
      gap: $gap * 1.5;
      max-width: 100%;
    }

    &-info {
      overflow: hidden;
    }

    &-settings {
      position: static;
      @include display-less(phablet) {
        display: none;
      }
    }
  }
  &__box {
    width: 100%;
    z-index: 30;

    height: 96px;
    background-color: #6C26D6;
    position: relative;

    @include display-less(phablet) {
      height: 80px;
    }

    &::after {
      @include display-less(phablet) {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        z-index: 1;
        background-color: #797088;
      }
    }

    &::before {
      @include display-less(phablet) {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: var(--timeline-progress);
        height: 2px;
        z-index: 2;
        background-color: #FFFFFF;
      }
    }
  }
}
</style>
