<template>
<svg
  fill="none"
  viewBox="0 0 16 16"
  xmlns="http://www.w3.org/2000/svg"
>
  <g
    clip-path="url(#social-icon-instagram-id--1)"
  >
    <path
      fill="#fff"
      d="M8.237 1.333c-1.901 0-2.14.008-2.886.04-.746.033-1.254.146-1.7.31-.46.17-.85.399-1.24.77-.389.37-.628.742-.807 1.18-.174.424-.292.909-.326 1.618-.033.711-.042.939-.042 2.75 0 1.81.009 2.036.043 2.747.034.71.152 1.195.325 1.618.179.439.418.81.807 1.181.39.371.78.6 1.24.77.445.165.954.277 1.699.31.747.032.985.04 2.886.04 1.901 0 2.14-.008 2.886-.04.745-.033 1.254-.145 1.7-.31.46-.17.85-.399 1.239-.77.39-.37.628-.742.808-1.18.172-.425.29-.91.325-1.619.034-.71.042-.937.042-2.748 0-1.81-.008-2.038-.042-2.749-.035-.71-.153-1.194-.325-1.618a3.26 3.26 0 0 0-.808-1.18 3.444 3.444 0 0 0-1.24-.77c-.446-.164-.955-.277-1.7-.31-.747-.032-.985-.04-2.886-.04h.002Zm-.628 1.202h.628c1.869 0 2.09.006 2.829.038.682.03 1.052.139 1.3.23.326.12.559.265.804.498.245.234.396.456.524.767.095.235.21.588.24 1.238.034.702.041.913.041 2.693 0 1.779-.007 1.99-.04 2.693-.032.65-.146 1.002-.242 1.237a2.054 2.054 0 0 1-.523.766 2.177 2.177 0 0 1-.805.499c-.246.091-.617.2-1.3.23-.737.031-.959.038-2.828.038-1.87 0-2.09-.007-2.829-.039-.682-.03-1.053-.138-1.3-.23a2.182 2.182 0 0 1-.804-.498 2.06 2.06 0 0 1-.524-.766c-.096-.235-.21-.588-.241-1.238-.034-.703-.04-.914-.04-2.694 0-1.78.006-1.99.04-2.693.03-.65.145-1.003.24-1.238.128-.311.28-.533.525-.767.245-.233.478-.377.805-.498.246-.092.617-.2 1.3-.23.645-.028.895-.036 2.2-.038v.002Zm4.365 1.107c-.464 0-.84.358-.84.8 0 .441.376.8.84.8.463 0 .84-.359.84-.8 0-.442-.377-.8-.84-.8Zm-3.737.935C6.252 4.577 4.642 6.109 4.642 8c0 1.89 1.61 3.423 3.595 3.423S11.831 9.891 11.831 8c0-1.89-1.609-3.423-3.594-3.423Zm0 1.2c1.289 0 2.333.996 2.333 2.223 0 1.227-1.044 2.222-2.333 2.222-1.289 0-2.333-.995-2.333-2.222 0-1.227 1.044-2.222 2.333-2.222Z"
    />
  </g>
  <defs>
    <clipPath
      id="social-icon-instagram-id--1"
    >
      <path
        fill="#fff"
        d="M0 0h16v16H0z"
      />
    </clipPath>
  </defs>
</svg>
</template>

<script>
export default {}
</script>
