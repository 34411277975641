<template>
<svg
  fill="none"
  viewBox="0 0 16 16"
  xmlns="http://www.w3.org/2000/svg"
>
  <g
    clip-path="url(#social-icon-youtube-id--1)"
  >
    <path
      fill="#fff"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.26 3.011a2.01 2.01 0 0 1 1.41 1.419c.343 1.259.33 3.884.33 3.884s0 2.611-.33 3.87a2.01 2.01 0 0 1-1.41 1.419c-1.252.331-6.26.331-6.26.331s-4.995 0-6.26-.345a2.01 2.01 0 0 1-1.41-1.418C0 10.925 0 8.301 0 8.301S0 5.688.33 4.43a2.052 2.052 0 0 1 1.41-1.432C2.992 2.666 8 2.666 8 2.666s5.008 0 6.26.345ZM6.405 5.888 10.57 8.3l-4.165 2.413V5.888Z"
    />
  </g>
  <defs>
    <clipPath
      id="social-icon-youtube-id--1"
    >
      <path
        fill="#fff"
        d="M0 0h16v11.268H0z"
        transform="translate(0 2.667)"
      />
    </clipPath>
  </defs>
</svg>
</template>

<script>
export default {}
</script>
