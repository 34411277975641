export default {
  setCurrentPlayingEpisode (state, payload) {
    state.currentEpisode = payload
  },

  setCurrentPlayingEpisodeId (state, payload) {
    state.currentEpisodeId = payload
  },

  setCurrentPlayingPodcast (state, payload) {
    state.currentPodcast = payload
  },

  setChapters (state, payload) {
    state.chapters = payload
  },

  setPlayerStatus (state, payload) {
    state.status = payload
  },

  setCurrentTime (state, payload) {
    state.currentTime = payload
  },

  setSkipTo (state, payload) {
    state.skipTo = payload
  },

  setCountdown (state, payload) {
    state.countdown = payload
  },

  setPlayList (state, payload) {
    state.playlist = payload
  },

  setLoadingStatus (state, payload) {
    state.loading = payload
  },

  setVolume (state, payload) {
    state.volume = payload
  },

  addTooltipId (state, tooltipId) {
    if (!state.tooltipsId.includes(tooltipId)) {
      state.tooltipsId.push(tooltipId)
    }
  },

  removeTooltipId (state, tooltipId) {
    state.tooltipsId = state.tooltipsId.filter(id => id !== tooltipId)
  }
}
