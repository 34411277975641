import atob from 'atob'
import dayjs from 'dayjs'
import { config } from '@/config'

export function formatMoney (num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function getCurrencySymbol (currency) {
  switch (currency) {
    case 'USD':
      return '$'
    case 'EUR':
      return '€'
    case 'RUB':
      return '₽'
    default:
      return currency
  }
}

export function hexToRgb (hex, opacity) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  let color = parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16)

  if (opacity) {
    let alpha = opacity

    if (opacity > 1) {
      alpha = opacity / 100
    }

    color = `rgba(${color}, ${alpha})`
  }

  return result ? color : null
}

export function parseIdToken (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return JSON.parse(jsonPayload)
}

export function generateUniqueAssetName (mimetype) {
  let extension = mimetype.split('/')[1] || 'jpg'
  const data = new Date()
  const year = data.getFullYear()
  const month = data.getMonth()
  const day = data.getDate()
  const minutes = data.getMinutes()
  const seconds = data.getSeconds()
  const dateNow = data.getTime()
  const id = `f${(~~(Math.random() * 1e8)).toString(16)}`

  if (extension === 'jpeg') {
    extension = 'jpg'
  }

  const uniqueName = `${year}${month}${day}_${minutes}${seconds}_${dateNow}${id}.${extension}`
  return uniqueName
}

export function testStringForRegExp (re, str) {
  if (re.test(str)) {
    return true
  }
  return false
}

export function copyToClipboard (text) {
  const el = document.createElement('textarea')
  el.value = text
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  el.style.zIndex = '-9999'
  el.style.opacity = '0'
  el.style.width = '1px'
  el.style.height = '1px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export function cleanCDATA (text) {
  if (!text || typeof text !== 'string') {
    return ''
  }
  if (text && text.startsWith('<![CDATA[') && text.endsWith(']]>')) {
    return text.slice(9, text.length - 3)
  }

  return text
}

export const htmlentities = (function () {
  return {
    encode: (str) => {
      const buf = []

      for (let i = str.length - 1; i >= 0; i--) {
        buf.unshift(['&#', str[i].charCodeAt(), ''].join(''))
      }

      return buf.join('')
    },
    decode: (str) => {
      if (!str) {
        return str
      }
      return str.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec)
      })
    }
  }
})()

export function randomInteger (min, max) {
  const rand = min + Math.random() * (max + 1 - min)
  return Math.floor(rand)
}

export function convertToDuration (duration) {
  const durationPerSecond = parseInt(duration)
  const durationPerMinute = Math.round(durationPerSecond / 60)
  const hourses = parseInt((durationPerMinute / 60).toFixed(0))
  const minutes = (durationPerMinute % 60).toFixed(0)
  let result = minutes + 'M'
  if (hourses) {
    result = hourses + 'H' + result
  }
  return result
}

export function clearStringBeforeSendToAPI (str) {
  str = str.replaceAll('>', '').replaceAll('<', '')
  str = str.replaceAll('&gt;', '').replaceAll('&lt;', '')
  return str
}

export function getResizedImageUrl (size, podcastSlug, filename) {
  const result = {
    default: config.imageResizerUrl + '/' + podcastSlug + '/' + size + '/' + filename,
    retina: config.imageResizerUrl + '/' + podcastSlug + '/' + (size * 2) + '/' + filename
  }
  return result
}

export function getMobileOperatingSystem () {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return undefined
}

export function prettifyAudioDuration (duration, full = false) {
  if (!duration) {
    if (full) {
      return '00:00:00'
    }
    return '00:00'
  }

  let result = ''
  let seconds = Math.floor(duration % 60)
  let hours = Math.floor(duration / 60 / 60)
  let minutes = Math.floor(duration / 60) - (hours * 60)

  if (seconds >= 0 && seconds < 10) {
    seconds = `0${seconds}`
  }

  if (minutes >= 0 && minutes < 10) {
    minutes = `0${minutes}`
  }

  if ((hours > 0 || full) && hours < 10) {
    hours = `0${hours}`
  }

  if (hours) {
    result += hours + ':'
  }

  result += minutes + ':' + seconds
  return result
}

export function removeAllSpecialCharacters (payload) {
  const specialCharactersRegEx = /[`@#$%^&*()_\-=\\[\]{};':"\\|<>\\/~]/gi
  return payload.replace(specialCharactersRegEx, '')
}

export function formatDate (date, locale) {
  let format
  switch (locale) {
    case 'en':
      format = 'MMM DD, YYYY'
      break
    default:
      format = 'DD/MM/YYYY'
  }

  const parsedDate = Date.parse(date)
  const result = dayjs(parsedDate).locale(locale).format(format)
  return result
}
