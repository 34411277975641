<template>
<svg
  fill="none"
  viewBox="0 0 16 16"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill="#fff"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM6.03 4.775c-.126.5-.638.788-1.226.688-.625-.1-.975-.713-.75-1.313.15-.375.513-.6.975-.587.7-.013 1.163.55 1 1.212Zm4.849 7a32.557 32.557 0 0 1 1.562 0c.113 0 .138-.037.138-.138V8.65c0-.237-.013-.475-.05-.712-.15-1-.75-1.65-1.688-1.8-.812-.125-1.5.075-1.987.787l-.063.063V6.35c0-.1-.025-.125-.125-.125h-1.6c-.1 0-.125.025-.125.138.013.962.013 1.937.013 2.912 0 .8 0 1.588-.013 2.375 0 .1.025.125.125.125h1.6c.088.012.138-.013.125-.113a1.707 1.707 0 0 1-.002-.238l.002-.111V8.688c0-.375.113-.688.4-.913.538-.437 1.288-.2 1.475.45.063.2.075.413.075.625v2.787c0 .113.025.138.138.138Zm-4.937-.15V9c0-.888 0-1.763.025-2.638 0-.1-.013-.137-.125-.137H4.229c-.1 0-.125.037-.125.125v5.3c0 .1.025.125.125.125l.525-.003c.35-.003.7-.005 1.05.003.113 0 .138-.038.138-.15Z"
  />
</svg>
</template>

<script>
export default {}
</script>
